import moment from 'moment'
import ConfirmDialog from '../../components/confirm/confirm.vue'

const defaultCalendarEventsParams = {
  fromDateTime: moment().startOf('year').format(),
  toDateTime: '2022-12-31',
  userId: null
}

export default {
  name: 'LeaveManagement',
  components: {
    ConfirmDialog
  },
  data () {
    return {
      loading: false,
      startDateMenuVisible: false,
      startDate: '',
      endDateMenuVisible: false,
      endDate: '',
      comment: '',
      selectedType: '',
      availableDates: [],
      allLeaveTypes: []

    }
  },

  computed: {
    otherLeaveType () {
      return this.leaveTypes.find(lt => lt.value.toLowerCase() === 'other')
    },
    paidLeaveType () {
      return this.leaveTypes.find(lt => lt.value.toLowerCase() === 'paidleave')
    },
    leaveManagementUserDetails () {
      return this.$store.getters['leaveManagement/userDetails']
    },

    leaveManagementUserCalendarEvents () {
      const requests = this.$store.getters['leaveManagement/userCalendarEvents']
      return requests
    },
    leaveTypes () {
      const leaveTypes = this.$store.getters.getLeaveTypes
      if (leaveTypes.length > 0) {
        leaveTypes.forEach(type => {
          type.icon = 'stop'
        })
      }
      this.allLeaveTypes = leaveTypes
      return leaveTypes.filter(lt => lt.accessLevel === 0)
    },

    /**
     * Returns the users number of days of remaining leave
     * @returns
     */
    remainingLeave () {
      if (this.leaveManagementUserCalendarEvents != null && this.leaveManagementUserCalendarEvents.leaveEntitlement != null) {
        return this.leaveManagementUserCalendarEvents.leaveEntitlement.remainingLeave
      }
    },

    /**
     * Returns the users number of days of leave entitlement
     * @returns
     */
    entitlement () {
      if (this.leaveManagementUserCalendarEvents != null && this.leaveManagementUserCalendarEvents.leaveEntitlement != null) {
        return this.leaveManagementUserCalendarEvents.leaveEntitlement.entitlement
      }
    },

    /**
     * Returns the users details
     * @returns
     */
    user () {
      return this.$store.getters.getUser
    }
  },
  watch: {
    selectedType () {
      this.comment = ''
    }
  },

  methods: {
    requestType (event) {
      const requestName = this.allLeaveTypes.filter(rt => rt.name.replace('/', '').replace(' ', '') === event.replace('/', '').replace(' ', ''))
      return requestName
    },
    allowedDates (a) {
      return this.availableDates.includes(a)
    },
    pickerUpdate: function (val) {
      const totalDay = moment(val, 'YYYY-MM').daysInMonth()

      const availableDates = []

      const day = 1

      for (let i = day; i <= totalDay; i++) {
        const date = moment().year(val.split('-')[0]).month(val.split('-')[1] - 1).date(i).format('YYYY-MM-DD')
        // check if day is not a SUN or SAT. If isn't then add to available dates
        if (moment(date).day() !== 0 && moment(date).day() !== 6) {
          availableDates.push(date)
        }
      }
      this.availableDates = availableDates
      this.allowedDates()
    },
    async getLeaveManagementUserDetails () {
      this.loading = true
      try {
        await this.$store.dispatch('leaveManagement/getUserDetails')
      } catch (err) {
        this.$store.commit('setUserDetails', null)
      } finally {
        this.loading = false
      }
    },

    async getLeaveManagementUserCalendarEvents () {
      this.loading = true
      try {
        await this.$store.dispatch('leaveManagement/getUserCalendarEvents', defaultCalendarEventsParams)
      } catch (err) {
        this.$store.commit('setUserCalendarEvents', null)
      } finally {
        this.loading = false
      }
    },

    async makeLeaveRequest () {
      const scope = this
      this.loading = true
      const userRequests = this.leaveManagementUserCalendarEvents

      // time set to 01:00:00 to match db time to allow importing approved holidays table data into attendances table
      // const startDateTime = `${this.startDate} 01:00:00`
      // const endDateTime = `${this.endDate} 01:00:00`
      // Find selected leave type
      const selectedLeaveType = this.leaveTypes.filter(lt => lt.id === this.selectedType)
      const params = {
        fromDateTime: this.startDate,
        toDateTime: this.endDate,
        leaveType: selectedLeaveType[0],
        jobId: '08d8d42b-6eaa-6762-5eb3-3f9b476a8905',
        requestStatus: 'Waiting',
        userId: this.leaveManagementUserDetails.appUser.id,
        requestComment: this.comment,
        deductHolidays: selectedLeaveType.deductLeave
      }

      if (userRequests.bookedDays !== null) {
        // Get list of non rejected requests. If request is rejected you can still make requests for that day
        const nonRejectedRequests = userRequests.bookedDays.filter(bd => bd.requestStatus !== 'Rejected' && bd.requestStatus !== 'Cancelled')
        const bookedFromDays = nonRejectedRequests.map(bd => moment(bd.from).format('YYYY-MM-DD'))
        const bookedToDays = nonRejectedRequests.map(bd => moment(bd.to).format('YYYY-MM-DD'))
        const allBookedDays = bookedFromDays.concat(bookedToDays)
        // Check if from or to days include selected start and end date. If does return snotify and
        if (allBookedDays.includes(scope.startDate) || allBookedDays.includes(scope.endDate)) {
          this.$snotify.error('Request already exists')
          this.loading = false
        } else {
          await this.sendRequest(params)
        }
      } else {
        await this.sendRequest(params)
      }
    },
    async sendRequest (params) {
      try {
        await this.$store.dispatch('leaveManagement/makeLeaveRequest', params)
        await this.getLeaveManagementUserDetails()
        await this.getLeaveManagementUserCalendarEvents()
        this.$snotify.success('Leave request sent for approval.')
        this.endDate = ''
        this.startDate = ''
        this.comment = ''
        this.selectedType = null
        this.$emit('updateHolidays')
      } catch (err) {
        this.$snotify.error(err.message)
        this.loading = false
      } finally {
        this.loading = false
      }
    },

    /**
     * Confirm cancellation of the leave request
     * @param {*} request
     */
    async deleteLeaveRequest (request) {
      this.$refs.confirm.open('Cancel Leave Request', 'Are you sure you want to cancel this leave request?')
        .then((confirm) => {
          if (confirm) {
            this.deleteRequest(request)
          }
        })
    },

    /**
     * Cancel the leave request
     * @param {*} request
     */
    async deleteRequest (request) {
      try {
        await this.$store.dispatch('leaveManagement/deleteLeaveRequest', request.attendanceId)
        await this.getLeaveManagementUserDetails()
        await this.getLeaveManagementUserCalendarEvents()
        this.$snotify.success('Leave request deleted.')
      } catch (err) {
        this.$snotify.error(err.message)
      }
    }
  },

  async mounted () {
    await this.getLeaveManagementUserDetails()
    await this.getLeaveManagementUserCalendarEvents()
  },

  async created () {
    await this.$store.dispatch('getLeaveTypes')
  }
}
